<script setup lang="ts">
import { computed } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'

const { providerTitle } = useProvider()
const { pages, getCurrentImage } = useCms()
const { translate } = useLocalization

const automotivePage = computed(() => pages.value.automotive)
const banner = getCurrentImage(automotivePage.value?.settings?.banner)
const bannerUrl = computed(
  () => banner.image || '//ucarecdn.com/2934df8b-44fa-4a9c-9525-02b7872e3025/'
)
const bannerAlt = computed(() => banner.alt)

function click() {
  window.open('https://mbacarassist.com.au', '_blank')
}
</script>

<template>
  <div class="mx-auto flex h-full w-full max-w-7xl">
    <div
      class="flex flex-col overflow-y-auto sm:mr-6 sm:flex-row sm:overflow-y-hidden sm:rounded-t-3xl"
    >
      <div class="bg-white sm:w-1/2">
        <img
          v-if="bannerUrl"
          :src="cdn(bannerUrl).resize('800x').url()"
          :alt="bannerAlt"
        />
      </div>
      <div class="markup sm:h-full sm:w-1/2 sm:overflow-y-auto">
        <div class="bg-gray-50 p-[30px] sm:rounded-tr-3xl">
          <h1 class="text-3xl">
            MBA Car Assist is here to help {{ providerTitle }} members with all
            things Automotive
          </h1>

          <p class="mt-12">
            <strong><u>MBA Car Buying Service:</u></strong>
          </p>
          <p>
            Looking to purchase a new vehicle at an extremely competitive price?
          </p>
          <p>
            With no obligations and no additional costs or service fees, The MBA
            Car Buying Service can help.
          </p>

          <p class="mt-12">
            <strong><u>Corporate Vehicle Benefits:</u></strong>
          </p>
          <p>
            Get the most out of your new vehicle with access to corporate
            program benefits from brands such as BMW, Lexus, Mazda and more.
          </p>

          <p class="mt-12">
            <strong>
              <u>Aftercare {{ translate('common.and') }} Protection:</u>
            </strong>
          </p>
          <p>
            Whether it be to protect a new vehicle or to rejuvenate your current
            vehicle, we offer premium aftercare
            {{ translate('common.and') }} protection products at great pricing.
          </p>

          <p class="mt-12">
            <strong><u>Accessories:</u></strong>
          </p>
          <p>
            Simple, compatible and no fuss. Automotive accessories available to
            purchase at great pricing.
          </p>

          <base-button full-width class="my-[30px]" @click="click">
            Explore Now
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
